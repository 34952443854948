:root {
    --color-primary: #0080CB;
    --color-primary-transparent: rgba(0, 128, 203, 0.51);

    --color-secondary: #E30613;
    --color-text-default: #383838;
    --color-text-muted: #868E96;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: rgba(194, 194, 194, 0.41);
    --color-dark-grey: #BEC2C6;
    --color-dark: #656565;
    --color-grey: #333333;
    --color-lightest-grey: rgb(240, 240, 240);

    --color-success: #28A745;
    --color-booking: #5CB85C;
    --color-booking-dark: #458a45;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-hochkoenig: #0075BF;
    --color-sbg-sportwelt: #E30613;
    --color-gastein: #00143D;
    --color-grossarltal: #F39200;
    --color-schladming-dachstein:#88C84B;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #235275;
    --color-secondary-dark: #a5040d;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #CFE0F5;
    --color-secondary-light: #e84952;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --gradient: linear-gradient(199.2deg, #E30613 0%, #0080CB 100%);

    --font-default: "Open-Sans-Reg", sans-serif;
    --font-default-bold: "Tondo-Bold", sans-serif;
    --font-decorative: "Tondo-Bold", sans-serif;

    --font-size-default: calc(18rem/16);

    --main-nav-height: calc(90rem/16);
    --main-nav-height-mobile: calc(50rem/16);

}
